import React, { useState, useEffect } from 'react';

const AddCourse = () => {

    const [courseCardData, setCourseCardData] = useState({
        CourseName: "",
        Category: "",
        Description: ""
    });

    const [showOption, setShowOption] = useState(true);




    const [getCategory, setGetCategory] = useState([]);

    const getData = async () => {
        const res = await fetch("https://codify-backend-qgvr.onrender.com/get-category", {
            method: "GET",
            headers: {
                "Content-Type": "application/json"
            }
        });

        const data = await res.json();
        // console.log(data[0].category);

        if (res.status === 422 || !data) {
            console.log("error ");

        } else {
            setGetCategory(data)
            console.log(data.length)
            
        }
    }

    useEffect(() => {
        getData();
    }, [])



    const handelCourseCardData = (e) => {
        const { name, value } = e.target;

        setCourseCardData(() => {
            return {
                ...courseCardData,
                [name]: value
            };
        });
    };



    const storeCourseCardData = async (e) => {
        e.preventDefault();

        const { CourseName, Category, Description } = courseCardData;

        try {
            const response = await fetch("https://codify-backend-qgvr.onrender.com/course-card", {
                method: "post",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({ CourseName, Category, Description })
            });

            const responseData = await response.json();

            if (responseData.status === 201) {
                setCourseCardData({ ...courseCardData, CourseName: "", Category: "", Description: "" });
            }

        } catch (error) {
            console.log(error);
        }
    };




    return (
        <>
            <div>
                <h2>Add Course</h2>
                <form style={{ display: 'flex', flexDirection: 'column' }}>
                    <input type="text" name="CourseName" id="CourseName" onChange={handelCourseCardData} value={courseCardData.CourseName} placeholder='Course Name' />
                    <select
                        name="Category"
                        id="Category"
                        onChange={handelCourseCardData}
                        value={courseCardData.Category}
                    >
                        <option value="">Select Category</option>
                        {getCategory.map((element, id) => (
                            <option key={id} value={element.category}>
                                {element.category}
                            </option>
                        ))}
                    </select>
                    <textarea name="Description" id="Description" onChange={handelCourseCardData} value={courseCardData.Description} cols="30" rows="10"></textarea>
                    <button onClick={storeCourseCardData}>Submit</button>
                </form>
                
{/* 
                {getCategory.map((element,id) =>{
                    return(
                        <>
                            <img src={require(`../../uploads/CardImage/${element.ImageName}`)} alt={element.category} height='100rem'/>
                        </>
                    )
                })} */}
            </div>
        </>
    )
}

export default AddCourse