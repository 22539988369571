import React, { useState, useEffect } from "react";
import { Bars } from "react-loader-spinner";
import { useNavigate } from "react-router-dom";
import "./CourseCard.css";
import SidebarCourse from "./SidebarCourse";
import DropDown from "./DropDown";

const CourseCard = () => {
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const gotToCourseDataPage = (data) => {
    navigate(`/${data}/coursedata`, { state: { data } });
  };

  const [getCategory, setGetCategory] = useState([]);
  const [cardData, setCardData] = useState([]);
  const [head, setHead] = useState("Full Stack Web Development");

  const getData = async () => {
    const res = await fetch(
      "https://codify-backend-qgvr.onrender.com/get-category",
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    const data = await res.json();

    if (res.status === 422 || !data) {
      console.log("error ");
    } else {
      setGetCategory(data);
    }
  };

  const getConstData = async () => {
    const res = await fetch(
      `https://codify-backend-qgvr.onrender.com/Full Stack Web Development/get-courses`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    const headingData = await res.json();
    // console.log(data[0].category);

    if (res.status === 422 || !headingData) {
      console.log("error ");
    } else {
      setCardData(headingData);
      setLoading(false);
    }
  };

  const getCoursesData = async (name) => {
    setHead(name);
    const res = await fetch(
      `https://codify-backend-qgvr.onrender.com/${name}/get-courses`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    const headingData = await res.json();
    // console.log(data[0].category);

    if (res.status === 422 || !headingData) {
      console.log("error ");
    } else {
      setCardData(headingData);
      setLoading(false);
    }
  };

  useEffect(() => {
    getData();
    getConstData();
  }, []);

  return (
    <>
      {cardData ? (
        <>
          {loading ? (
            <div className="Loading">
              <Bars
                height="80"
                width="80"
                color="yellow"
                ariaLabel="bars-loading"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
              />
            </div>
          ) : (
            <>
              <section className="coursecard-main">
                <div>
                  <section className="sidebarcourse-main">
                    {getCategory.map((element, id) => (
                      <button
                        key={id}
                        value={element.category}
                        onClick={() => getCoursesData(element.category)}
                      >
                        {element.category}
                      </button>
                    ))}
                  </section>
                </div>
                <div className="coursecard-content">
                  <div className="coursecard-heading">
                    <h2> Confused with which course to select? </h2>
                    <h2>Explore our courses below!</h2>
                  </div>
                  <div className="coursecard-header">
                    <h2>{head}</h2>
                  </div>
                  <div className="course">
                    {cardData.map((element, id) => {
                      return (
                        <>
                          <div div className="course-container">
                            <h2>{element.CourseName}</h2>
                            <p>{element.Description}</p>
                            <button
                              onClick={() =>
                                gotToCourseDataPage(element.CourseName)
                              }
                            >
                              Get Started
                            </button>
                          </div>
                        </>
                      );
                    })}
                  </div>
                </div>
              </section>
              <div className="display-mobile">
                <DropDown />
              </div>
            </>
          )}
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export default CourseCard;
