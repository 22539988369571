import React from 'react'
import { Link } from 'react-router-dom'
import './Footer.css'
import logo from './../assests/Navlogo.png'
import yt from './../assests/yt.png'
import ig from './../assests/ig.png'
import link from './../assests/link.png'
const Footer = () => {
    return (
        <div>
            <footer className="footer-main">
                <div className="footer-container">
                    <div className="footer-first footer-center">
                        <img className='logo' src={logo} alt="" />
                        <p>Follow Us On:</p>
                        <div className="sep"></div>
                        <div className="footer-social">
                            <ul>
                                {/* <li><Link to='https://www.instagram.com/codifyinstitute/'><img src={fb} alt="" /></Link></li> */}
                                <li><Link to='https://www.instagram.com/codifyinstitute/'><img src={ig} alt="" /></Link></li>
                                <li><Link to='https://www.linkedin.com/company/codify-software-development-company/'><img src={link} alt="" /></Link></li>
                                <li><Link to='https://youtube.com/@CodifyInstitute?si=hfUmG9YYe8GOMQOz'><img src={yt} alt="" /></Link></li>
                            </ul>
                        </div>
                    </div>
                    <div className="footer-second footer-center">
                        <h2>Menu</h2>
                        <div className="footer-menu">
                            <ul>
                                <li><Link to='/'>Home</Link></li>
                                <li><Link to='/Courses'>Courses</Link></li>
                                <li><Link to='/placement'>Placements</Link></li>
                                <li><Link to='/contactus'>Contact Us</Link></li>
                            </ul>
                        </div>
                    </div>
                    <div className="footer-third footer-center">
                        <h2>Courses</h2>
                        <div className="footer-menu">
                            <ul>
                                <li><Link to='/Courses'>Full Stack Web Development</Link></li>
                                <li><Link to='/Courses'>Mobile App Development</Link></li>
                                <li><Link to='/Courses'>Data Science</Link></li>
                                <li><Link to='/Courses'>View More..</Link></li>
                            </ul>
                        </div>
                    </div>
                    <div className="footer-forth footer-center">
                        <h2>Contact</h2>
                        <div className="footer-contacts">
                            <div className="footer-call">
                                <p>Call Us:</p>
                                <div className="sep"></div>
                                <p><a href="tel:+91 9028793459">+91 9028793459</a></p>
                            </div>
                            <div className="footer-email">
                                <p>Email:</p>
                                <div className="sep"></div>
                                <p><a href="mailto:codify.institute@gmail.com">codify.institute@gmail.com</a></p>
                            </div>
                        </div>
                        <div className="footer-address">
                            <p>Address:</p>
                            <div className="sep sep1"></div>
                            <p>Shop no 201 /202, Gold crest 369, above Zudio,<br/> Near New ViVa college, Virar West,<br/>  Maharashtra 401303</p>
                        </div>
                    </div>
                </div>
            </footer>
        </div>
    )
}

export default Footer
