import React, { useState, useEffect } from 'react'
import { useLocation,useNavigate } from 'react-router-dom';
import './mix.css'


const EditCorseData = () => {
    const location = useLocation();
    const { data } = location.state || {};

    const navigate = useNavigate()

    const gotToSubheadingPage = (Heading) => {
        console.log(Heading)
        navigate("/editSubheading", {state:{Heading,data}});
    }

    const [skill, setSkill] = useState("");
    const [wywl, setWywl] = useState("");
    const [heading, setHeading] = useState("");
    const [skillGet, setSkillGet] = useState([]);
    const [wywlGet, setWywlGet] = useState([]);
    const [headingGet, setHeadingGet] = useState([]);



    const getSkills = async () => {
        const res = await fetch(`https://codify-backend-qgvr.onrender.com/${data}/get-skill`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json"
            }
        });

        const skillData = await res.json();
        // console.log(data[0].category);

        if (res.status === 422 || !skillData) {
            console.log("error ");

        } else {
            setSkillGet(skillData)
        }
    }

    const getWywl = async () => {
        const res = await fetch(`https://codify-backend-qgvr.onrender.com/${data}/get-wywl`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json"
            }
        });

        const wywlData = await res.json();
        // console.log(data[0].category);

        if (res.status === 422 || !wywlData) {
            console.log("error ");

        } else {
            setWywlGet(wywlData)
        }
    }

    const getHeading = async () => {
        const res = await fetch(`https://codify-backend-qgvr.onrender.com/${data}/get-heading`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json"
            }
        });

        const headingData = await res.json();
        // console.log(data[0].category);

        if (res.status === 422 || !headingData) {
            console.log("error ");

        } else {
            setHeadingGet(headingData)
        }
        console.log(headingGet)
    }

    useEffect(() => {
        getSkills();;
        getWywl();
        getHeading();
    }, [])

    const skillHandler = (e) => {
        setSkill(e.target.value);
    }

    const wywlHandler = (e) => {
        setWywl(e.target.value);
    }

    const headingHandler = (e) => {
        setHeading(e.target.value);
    }

    const storeSkill = async (e) => {
        e.preventDefault();

        try {
            const res = await fetch(`https://codify-backend-qgvr.onrender.com/${data}/add-skill`, {
                method: "post",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({ skill })
            });

            const resData = await res.json();

            if (resData.status === 201) {
                setSkill("");
            }
        } catch (error) {
            console.log(error);
        }
    }

    const storeWywl = async (e) => {
        e.preventDefault();

        try {
            const res = await fetch(`https://codify-backend-qgvr.onrender.com/${data}/add-wywl`, {
                method: "post",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({ wywl })
            });

            const resData = await res.json();

            if (resData.status === 201) {
                setWywl("");
            }
        } catch (error) {
            console.log(error);
        }
    }

    const storeHeading = async (e) => {
        e.preventDefault();

        try {
            const res = await fetch(`https://codify-backend-qgvr.onrender.com/${data}/add-heading`, {
                method: "post",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({ heading })
            });

            const resData = await res.json();

            if (resData.status === 201) {
                setHeading("");
            }
        } catch (error) {
            console.log(error);
        }
    }

    return (
        <>
            <h2 className='text-center'>{data}</h2>
            <div className="grid-3">
                <div className='box'>
                    <h3 className='text-center'>SKills</h3>
                    <form>
                        <input type="text" name="skill" id="skill" placeholder='Enter Skill' onChange={skillHandler} value={skill} />
                        <button onClick={storeSkill}>ADD</button>
                    </form>
                    <div className="skillData">
                        {skillGet.map((element, id) => {
                            return (
                                <>
                                    <div className="skill">{element}</div>
                                </>
                            )
                        })}
                    </div>
                </div>
                <div className='box'>
                    <h3 className='text-center'>What You will Learn</h3>
                    <form>
                        <input type="text" name="wywl" id="wywl" placeholder='Enter Learning Points' onChange={wywlHandler} value={wywl} />
                        <button onClick={storeWywl}>ADD</button>
                    </form>
                    <div className="skillData">
                        {wywlGet.map((element, id) => {
                            return (
                                <>
                                    <div className="skill">{element}</div>
                                </>
                            )
                        })}
                    </div>
                </div>
                <div className='box'>
                    <h3 className='text-center'>Headings</h3>
                    <form>
                        <input type="text" name="heading" id="heading" placeholder='Enter Heading' onChange={headingHandler} value={heading} />
                        <button onClick={storeHeading}>ADD</button>
                    </form>
                    <div className="skillData">
                        {headingGet.map((element, id) => {
                            return (
                                <>
                                    <button onClick={() => gotToSubheadingPage(element.Heading)}>{element.Heading}</button>
                                </>
                            )
                        })}
                    </div>
                </div>
            </div>
        </>
    )
}

export default EditCorseData