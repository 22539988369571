// Contact.js
import React,{useEffect} from 'react';
import Footer from '../components/Footer';
import Navbar from '../components/Navbar';
import './Contact.css';

function Contact() {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, [])
  return (
    <>
      <Navbar />
      <div className="mian_cont">
        <div>
          <h2 className="head">LET'S CONNECT</h2>
        </div>

        <div className="contact_img">
          <div className="img_div1">
            <img
              className="let_connect_icon"
              src="https://t4.ftcdn.net/jpg/05/36/24/13/240_F_536241340_GsrsNhcWC0hyTVaJLilNafyDw6fl0cC8.jpg"
              alt=""
            />
            <h3 className="connect_point">Call us at</h3>
            <p>+91 9028 793 459</p>
          </div>
          <div className="img_div1">
            <img
              className="let_connect_icon"
              src="https://t4.ftcdn.net/jpg/02/54/62/25/240_F_254622588_6OClHyYpak64rVI8y9QVjUvDlStsDEu9.jpg"
              alt=""
            />
            <h3 className="connect_point">Address</h3>
            <p>Shop no 201 /202,</p>
            <p>Gold crest 369, above Zudio,</p>
            <p>Near New ViVa college, above zudio,</p>
            <p>Virar West, Maharashtra 401303</p>
            <p></p>
          </div>
          <div className="img_div1">
            <img
              className="let_connect_icon"
              src="https://static.vecteezy.com/system/resources/previews/022/647/958/original/email-icon-for-your-website-mobile-presentation-and-logo-design-free-vector.jpg"
              alt=""
            />
            <h3 className="connect_point">Email Us</h3>
            <p className='email-id'>codifyinstitute@gmail.com</p>
          </div>
        </div>
        <br /><br />

        {/* MAP */}
        <div>
       
        <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3761.8981963801803!2d72.79787467521498!3d19.459955581825344!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7ab059e821c33%3A0x28d1b7b9c3d25fb9!2sCodify%20Institute!5e0!3m2!1sen!2sin!4v1713523749072!5m2!1sen!2sin"
        width="80%"
        height="350px"
        style={{ border: '0' }}
        allowFullScreen=""
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"
      ></iframe>
          
        </div>
        <br /><br /><br />
      </div>
      <Footer />
    </>
  );
}

export default Contact;
