import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Bars } from 'react-loader-spinner';
import "./Admin.css";

const EnqData = () => {
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([]);
    const location = useLocation();
    const condition = location.state || {};

    const history = useNavigate();
    const validate = () => {
        if (condition !== "Pass") {
            history('/adminlogin')
        }
        else {
            // setLoading(false);
        }
    }

    const getData = async () => {
        const res = await fetch(`https://codify-backend-qgvr.onrender.com/enquiries`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json"
            }
        });

        const data = await res.json();

        if (res.status === 422 || !data) {
            console.log("error ");

        } else {
            setData(data.enquiries);
            setLoading(false);
            // console.log(data.enquiries)
        }
    }
    useEffect(() => {
        getData();
        validate();
    })

    const delData = async (id) => {
        try {
            const res = await fetch(`https://codify-backend-qgvr.onrender.com/enquiry/${id}`, {
                method: "DELETE",
                headers: {
                    "Content-Type": "application/json"
                }
            });

            const data = await res.json();

            if (res.status === 204) {

            } else {
                // console.log("Error deleting enquiry:", data.error);
                // console.log(`Error deleting enquiry. Status: ${res.status}, Message: ${data.error}`);
            }
        } catch (error) {
            console.error("Delete error:", error);
        }
    }


    return (
        <>
            {loading ? (<div className='Loading'><Bars
                height="80"
                width="80"
                color="yellow"
                ariaLabel="bars-loading"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
            /></div>) : (<div className='enq-container'>
                <h2 style={{ textAlign: "center" }}>Queries</h2>
                <table>
                    <tr>
                        <th>Name</th>
                        <th>Mobile No.</th>
                        <th>Email</th>
                        <th>Course Name</th>
                        <th>Date</th>
                        <th>Time</th>
                        <th>Delete</th>
                    </tr>
                    {data.map((element, id) => {
                        return (
                            <>
                                <tr>
                                    <td>{element.Name}</td>
                                    <td>{element.MobileNo}</td>
                                    <td>{element.Email}</td>
                                    <td>{element.CourseName}</td>
                                    <td>{element.enquiryDate}</td>
                                    <td>{element.enquiryTime}</td>
                                    <td><button onClick={() => delData(element._id)}>Delete</button></td>
                                </tr>
                            </>
                        )
                    })}
                </table>

            </div>)}
        </>
    )
}

export default EnqData