import React from 'react'
import AddCategory from './../components/Admin/AddCategory'
import AddCourse from './../components/Admin/AddCourse'
import CourseButtons from './../components/Admin/CourseButtons'

const newAdmin = () => {
    return (
        <>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <h1>Admin Panel</h1>
                <AddCategory/>
                <AddCourse/>
                <CourseButtons/>
            </div>
        </>
    )
}

export default newAdmin