import React, { useState,useEffect } from 'react'
import { useLocation } from 'react-router-dom';

const EditSubheading = () => {
    const location = useLocation();
    const { Heading, data } = location.state || {};
    console.log(data)

    const [subheading, setSubheading] = useState("");
    const [getSubheading, setGetSubheading] = useState([]);

    const getData = async () => {
        const res = await fetch(`https://codify-backend-qgvr.onrender.com/${data}/${Heading}/get-subHeading`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json"
            }
        });

        const subheadingData = await res.json();
        // console.log(data[0].category);

        if (res.status === 422 || !subheadingData) {
            console.log("error ");

        } else {
            setGetSubheading(subheadingData)
        }
    }

    useEffect(() => {
        getData();
    }, [])

    const storeSubheading = async (e) => {
        e.preventDefault();

        try {
            const res = await fetch(`https://codify-backend-qgvr.onrender.com/${data}/${Heading}/add-subHeading`, {
                method: "post",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({ subheading })
            });

            const resData = await res.json();
            console.log(res.status)

            if (res.status === 201) {
                setSubheading("");
            }
        } catch (error) {
            console.log(error);
        }
    }

    const subheadingHandler = (e) => {
        setSubheading(e.target.value);
    }

    return (
        <>
            <h2 className='text-center'>{Heading}</h2>
            <form>
                <input type="text" name="skill" id="skill" placeholder='Enter Skill' onChange={subheadingHandler} value={subheading} />
                <button onClick={storeSubheading}>ADD</button>
            </form>
            <div className="skillData">
                {getSubheading.map((element, id) => {
                    return (
                        <>
                            <div className="skill">{element}</div>
                        </>
                    )
                })}
            </div>
        </>
    )
}

export default EditSubheading