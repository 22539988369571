import React, { useState, useEffect } from "react";
import Logo1 from "./../assests/Layer 2.png";
import "./Topcontainer.css";
import { Link } from "react-router-dom";

const Topcontainer = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setIsVisible(true);
    }, 1000); // 1 seconds

    const handleScroll = () => {
      const textContainer = document.getElementById("textContainer");
      const imageContainer = document.getElementById("imageContainer");

      if (isInViewport(textContainer) && isInViewport(imageContainer)) {
        setIsVisible(true);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      clearTimeout(timeout);
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  function isInViewport(element) {
    if (!element) return false;

    const rect = element.getBoundingClientRect();
    return (
      rect.left >= 1 &&
      rect.top >= 0 &&
      rect.bottom <=
        (window.innerHeight || document.documentElement.clientHeight) &&
      rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
  }

  return (
    <div className="container">
      <div
        id="textContainer"
        className={`hidden ${isVisible ? "visible" : "scrolling"}`}
      >
        <h1 className="touch_h1">
          A COURSE FOR <br /> EVERYONE
        </h1>
        <p className="touch_para">
          Learn to Code, Land Your Dream Job. Get 100% Practical trainings with
          Job Placement Guarantee on Paper.
        </p>
        <button className="touch_btn">
          <Link className="Get-in-btn" to="/contactus">
            Get In Touch
          </Link>
        </button>
      </div>
      <div
        id="imageContainer"
        className={`hidden ${isVisible ? "visible" : "scrolling"}`}
      >
        <img src={Logo1} alt="" />
      </div>
    </div>
  );
};

export default Topcontainer;
