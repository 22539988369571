import React from "react";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import "./Placement.css";
import Marquee from "react-fast-marquee";
import img1 from "./../assests/wipro.png";
import img2 from "./../assests/Cleanify.png";
import img3 from "./../assests/xyst.png";
import img4 from "./../assests/asap.png"
import img5 from "./../assests/Codehub.png"
import img6 from "./../assests/oracle.png";
import youngpeople from "./../assests/Group 96.png";

function Placement() {

  return (
    <>
      <Navbar />
      <div className="Contact">
        <div class="place_main">
          {/* <div class="place_main_sub">
            <img
              src={placement}
              alt=""
            />
          </div> */}

          <div class="place_main_sub" id="place_text">
            <p>
              Our customized courses & employment focused training have helped
              our students get placed in top companies !
            </p>
          </div>

          <div class="place_main_sub">
            <img src={youngpeople} alt="" />
          </div>
        </div>

        <div className="slider-scroll">
        <Marquee direction="left" speed={100} delay={1}>
          <div className="image_wrapper">
            <img src={img1} alt="" />
          </div>
          <div className="image_wrapper">
            <img src={img2} alt="" />
          </div>
          <div className="image_wrapper">
            <img src={img3} alt="" />
          </div>
          <div className="image_wrapper">
            <img src={img4} alt="" />
          </div>
          <div className="image_wrapper">
            <img src={img5} alt="" />
          </div>
          <div className="image_wrapper">
            <img src={img6} alt="" />
          </div>
        </Marquee>
      </div>


        <div class="stu_placed">
          <div>
            <h2>12,000+</h2>
            <p>Placement Drives Conducted by</p> <p>institute.</p>
          </div>
          <div>
            <h2>20LPA</h2>
            <p>Highest Package Received by</p> <p>Alumini</p>
          </div>
          <div>
            <h2>80+</h2>
            <p>Hiring Companies</p>
          </div>
        </div>

        <div class="email_con">
          <p>
            For <strong>Placement Enquiries,</strong> contact us on{" "}
            <strong>
              <a
                style={{ color: "#000000de" }}
                href="mailto:codifyinstitute@gmail.com"
              >
                <b>codifyinstitute@gmail.com</b>
              </a>
            </strong>
            <br />
            {" "}
            <strong>
              <a style={{ color: "#000000de" }} href="tel:+91 9028793459">
                <b>+91 9028793459</b>
              </a>
            </strong>
          </p>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Placement;
