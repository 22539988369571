import React from "react";
import "./Student.css";
import profile from "./../assests/profile.jpg";
const Student = () => {
  return (
    <section>
      <div className="student-main">
        <h2 className="sep">Our Students Say</h2>
        <div className="review-container">
          <div className="content">
            <div className="review-content">
              <p className="quote"> ❝</p>
              <div className="review">
                <p>
                  I enrolled in the Codify Institute's software training
                  program, and it has been an outstanding experience from start
                  to finish. The curriculum is incredibly comprehensive,
                  covering a broad spectrum of programming languages and
                  cutting-edge technologies.
                </p>
              </div>
            </div>
            <div className="sep1"></div>
            <div className="desi">
              <div className="image">
                <img src={profile} alt="" />
              </div>
              <div className="footer-heading">
                <p className="heading-review">Vikrant Gharat</p>
                <p>Mobile Developer</p>
              </div>
            </div>
          </div>
          <div className="content">
            <div className="review-content">
              <p className="quote"> ❝</p>
              <div className="review">
                <p>
                  Thank you so much kishor sir for JOB Placement. I have a great
                  experience with codify institute best mentor and working on
                  live project. once again thank you so much codify institute.
                </p>
              </div>
            </div>
            <div className="sep1"></div>
            <div className="desi">
              <div className="image">
                <img src={profile} alt="" />
              </div>
              <div className="footer-heading">
                <p className="heading-review">Pranali Sonawane</p>
                <p>Data Analysis</p>
              </div>
            </div>
          </div>
          <div className="content">
            <div className="review-content">
              <p className="quote"> ❝</p>
              <div className="review">
                <p>
                  At Codify Institute, I had an amazing time! First of all, at
                  only 1.8 km from Virar station, it's at a prime location. The
                  academic members are outstanding, well-informed, and very
                  cooperative. They are excellent at using simple language to
                  convey ideas.
                </p>
              </div>
            </div>
            <div className="sep1"></div>
            <div className="desi">
              <div className="image">
                <img src={profile} alt="" />
              </div>
              <div className="footer-heading">
                <p className="heading-review">Maitryee Vartak</p>
                <p>Flutter Developer</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Student;
