import React, { useEffect, useState } from 'react'
import { useLocation} from 'react-router-dom';
import { Bars } from 'react-loader-spinner';
import './CourseDetails.css'
import { TiTick } from "react-icons/ti";
import proLaptop from "./../assests/processLaptop.png"
import proMobile from "./../assests/ProcessPhone.png"
import clock from './../assests/clock-white-1.png'
import trained from './../assests/student-with-graduation-cap-white-1.png'
import companies from './../assests/building-white-1.png'
import money from './../assests/money-white-1.png'
import profile from './../assests/profile.jpg'
import Navbar from './Navbar'
import Footer from './Footer'
import { Link } from 'react-router-dom';


const CourseData = () => {
    const [loading, setLoading] = useState(true);
    const location = useLocation();
    const { data } = location.state || {};
    const [scrolledToTop, setScrolledToTop] = useState(false);
    const [courseData, setCourseData] = useState([]);

    const getData = async () => {

        const res = await fetch(`https://codify-backend-qgvr.onrender.com/${data}/get-courseData`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json"
            }
        });

        const Cdata = await res.json();

        if (res.status === 422 || !data) {
            console.log("error ");

        } else {
            setCourseData(Cdata);
            setLoading(false);
        }
    }

    useEffect(() => {
        getData();
        if (!scrolledToTop) {
            window.scrollTo({ top: 0, behavior: 'smooth' });
            setScrolledToTop(true);
        }
    },[courseData,getData])
    return (
        <>
        {loading ? (<div className='Loading'><Bars
                height="80"
                width="80"
                color="yellow"
                ariaLabel="bars-loading"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
            /></div>) : (<section>
                <Navbar />
                <div className="coursedetails-main">
                    <div className="coursedetails-header">
                        {courseData.map((element, id) => {
                            return (
                                <>
                                    <h2>{element.CourseName}</h2>
                                    <p>{element.Description}</p>
                                </>
                            )
                        })}
                        <Link to="/contactus"> <button className='enroll'> Enroll Now </button> </Link>
                        {/* <button className='enroll' >Enroll Now</button> */}
                    </div>
                    <div className="coursedetails-learn">
                        <div className="learn-head">
                            <h2>What you'll learn</h2>
                            {/* <img src={learn} alt="" /> */}
                        </div>
                        <div className="tick-learn">
                            <div className="learn">
                                <ul>
                                    {courseData.map((element, id) => {
                                        return (
                                            <>
                                                {element.WYWL.map((welement, wid) => {
                                                    return (
                                                        <>
                                                            <li><TiTick />{welement}</li>
                                                        </>
                                                    )
                                                })}
                                            </>
                                        )
                                    })}
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="coursedetails-process">
                        <div className="learn-process">
                            <h2>Our Training Process</h2>
                            {/* <img className='training1-img' src={training} alt="" /> */}
                        </div>
                        <div className="process-content-images">
                            <img className="content-lap" src={proLaptop} alt="" />
                            <img className="content-mob" src={proMobile} alt="" />
                        </div>
                        {/* <div className="process-content">
                            <div className="practical arrow-side">
                                <div className="image-title">
                                    <img src={practical} alt="" />
                                    <p>Practical Session</p>
    
                                </div>
                                <IoArrowForward size={55} />
    
                            </div>
                            <div className="assignment arrow-side">
                                <div className="image-title">
                                    <img src={assignment} alt="" />
                                    <p>Assignment</p>
                                </div>
                                <IoArrowForward size={55} />
                            </div>
                            <div className="project arrow-side">
                                <div className="image-title">
                                    <img src={project} alt="" />
                                    <p>Projects</p>
    
                                </div>
                                <IoArrowForward size={55} />
                            </div>
    
                            <div className="resume arrow-side">
                                <div className="image-title">
                                    <img src={resume} alt="" />
                                    <p>Resume Building</p>
                                </div>
                                <IoArrowForward size={55} />
                            </div>
                            <div className="interview arrow-side">
                                <div className="image-title">
                                    <img src={interview} alt="" />
    
                                    <p>Interview Preparation</p>
                                </div>
                                <IoArrowForward size={55} />
                            </div>
                            <div className="suitcase arrow-side">
                                <div className="image-title">
    
                                    <img src={suitcase} alt="" />
                                    <p>Be Job Ready</p>
                                </div>
                            </div>
                        </div> */}
                    </div>
                    {/* <div className="coursedetails-process-mobile">
                        <div className="learn-process">
                            <h2>Our Training Process</h2>
                            <img src={training} alt="" />
                        </div>
    
                        <div className="process-content">
                            <div className="up-process-images">
                                <div className="practical arrow-side">
                                    <div className="image-title">
                                        <img src={practical} alt="" />
                                        <p>Practical Session</p>
    
                                    </div>
                                    <IoArrowForward size={55} />
    
                                </div>
                                <div className="assignment arrow-side">
                                    <div className="image-title">
                                        <img src={assignment} alt="" />
                                        <p>Assignment</p>
                                    </div>
                                    <IoArrowForward size={55} />
                                </div>
                                <div className="project arrow-side">
                                    <div className="image-title">
                                        <img src={project} alt="" />
                                        <p>Projects</p>
                                    </div>
                                    <IoArrowForward size={55} />
                                </div>
                            </div>
                            <div className="bottom-process-images">
                                <div className="resume arrow-side">
                                    <IoArrowForward size={55} />
                                    <div className="image-title">
                                        <img src={resume} alt="" />
                                        <p>Resume Building</p>
                                    </div>
                                </div>
                                <div className="interview arrow-side">
                                    <IoArrowForward size={55} />
                                    <div className="image-title">
                                        <img src={interview} alt="" />
    
                                        <p>Interview Preparation</p>
                                    </div>
                                </div>
                                <div className="suitcase arrow-side">
                                    <div className="image-title">
    
                                        <img src={suitcase} alt="" />
                                        <p>Be Job Ready</p>
                                    </div>
                                </div>
                            </div>
    
                        </div>
                    </div> */}
                    <div className="coursedetails-highlights">
                        <div className="highlight-bg">
                            <div className="highlights-heading">
                                <h2>Key Highlights</h2>
                                {/* <img src={highlights} alt="" /> */}
                            </div>
                            <div className="highlights-content">
                                <div className="highlights-left-content">
                                    <ul>
                                        <li>Personalized Career Coach</li>
                                        <li>Study Material</li>
                                        <li>90% Practical Training</li>
                                        <li>Instant Doubt Solving</li>
                                        <li>Certification</li>
                                        <li>Mock Interviews</li>
                                        <li>100% Job Assurance</li>
                                        <li>Live Projects</li>
                                    </ul>
                                </div>
                                <div className="highlights-right-content">
                                    <div className="duration">
                                        <img src={clock} alt="" />
                                        <h5>100+ Hrs</h5>
                                        <p>Training Duration</p>
                                    </div>
                                    <div className="duration">
                                        <img src={trained} alt="" />
                                        <h5>25000+</h5>
                                        <p>Students Trained</p>
                                    </div>
                                    <div className="duration">
                                        <img src={companies} alt="" />
                                        <h5>1000+</h5>
                                        <p>Hiring Companies</p>
                                    </div>
                                    <div className="duration">
                                        <img src={money} alt="" />
                                        <h5>8+ LPA</h5>
                                        <p>Highest Fresher Salary</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="coursedetails-course-content">
                        <div className='coursedetails-content-header'>
                            <h2>Course Content</h2>
                            {/* <img src={classroom} alt="" /> */}
                        </div>
                        <div className="course-content">
                            {courseData.map((element, id) => {
                                return (
                                    <>
                                        {element.CourseContent.map((ccelement, wid) => {
                                            return (
                                                <>
                                                    <div className="course-container">
                                                        <div className="course-css">
                                                            <h2>{ccelement.Heading}</h2>
                                                            <ul>
                                                                {ccelement.subheadings.map((shelement, wid) => {
                                                                    return (
                                                                        <>
                                                                            <li>{shelement}</li>
                                                                        </>
                                                                    )
                                                                })}
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </>
                                            )
                                        })}
    
                                    </>
                                )
                            })}
                        </div>
                    </div>
                    <div className="course-skills">
                        <div className="skills-heading">
                            <h2>Skills you will gain</h2>
                            {/* <img src={highlights} alt="" /> */}
                        </div>
                        <div className="coursedetails-button">
                            {courseData.map((element, id) => {
                                return (
                                    <>
                                        {element.Skills.map((selement, wid) => {
                                            return (
                                                <>
                                                    <button>{selement}</button>
                                                </>
                                            )
                                        })}
                                    </>
                                )
                            })}
                        </div>
                    </div>
                    <div className="coursedeatils-certificate">
                        <div className="certificate-heading">
                            <h2>Course Certification</h2>
                            {/* <img src={highlights} alt="" /> */}
                        </div>
                        {courseData.map((element, id) => {
                            return (
                                <div className="certificate-content">
                                    <div className="certificate-left">
                                        <p>Become a Certified {element.CourseName} with Codify Institute and enhance your career prospects to the next level.</p>
                                        <p>This certificate serves as an official badge of your successful {element.CourseName} course completion, highlighting your expertise.</p>
                                    </div>
                                    <div className="certificate-right">
                                        {/* <img src={learn} alt="" /> */}
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                    <div className="coursedetails-review">
                        <h2>Genuine Reviews For Our Courses</h2>
                        <div className="student-review">
                            <div className="content">
                                <div className="review-content">
                                    <p className='quote'> ❝</p>
                                    <div className="review">
                                        <p>I had enrolled for Full Stack Web Development Course in Codify Classes. This course has changed my vision and aspect of my career. Got to learn everything from scratch. Faculty was very professional and helpful throughout the course. Mehul and Monish sir helped me till the end in every way. To build my resume, to crack interviews in every possible way they were there with me. After completing this course i got a Job as Jr Software Developer via Codify Classes. I am very thankful to each and everyone from Codify to make me a better than before!</p>
    
                                    </div>
                                </div>
                                <div className="sep1"></div>
                                <div className="desi">
                                    <div className="image">
                                        {/* <img src={profile} alt="" /> */}
                                    </div>
                                    <div className="footer-heading">
                                        <p className='heading-review'>Zagesh Rathod</p>
                                        <p>Full Stack Developer</p>
                                    </div>
                                </div>
    
                            </div>
                            <div className="content">
                                <div className="review-content">
                                    <p className='quote'> ❝</p>
                                    <div className="review">
                                        <p>I did MERN stack course in Codify class and got a Full Stack developer job with the help of my mentor Mr Mohnish sir and Mehul sir. A very good class to upgrade your skills and faculties are extremely knowledgeable and quite supportive. After every topic, assignments were allotted to check our understanding of the topic and doubts were timely resolved. Mehul & Mohnish Sir are very good and regularly inquire about the studies. Other Faculties are equally good . I highly recommend this class to anyone who’s looking for a good start to their professional career</p>
    
                                    </div>
                                </div>
                                <div className="sep1"></div>
                                <div className="desi">
                                    <div className="image">
                                        <img src={profile} alt="" />
                                    </div>
                                    <div className="footer-heading">
                                        <p className='heading-review'>Pranali Shirke</p>
                                        <p>Android Developer</p>
                                    </div>
                                </div>
    
                            </div>
                            <div className="content">
                                <div className="review-content">
                                    <p className='quote'> ❝</p>
                                    <div className="review">
                                        <p>Hi my name is Bhagyashri Gunjate. I have completed full stack web development course from Codify and saying this happily that I also got my first job 2lacs per annual. Although I have gap of 4 years after completing my engineering Mohnish and Mehul sir gave me confidence that I can do it and at this age also I can be succeed in web development field. Talented and passionate faculty helps me to resolve my query and enable my base strong. They initiated new ways of thinking to improve project query and my personal performance as well. Also this helps me to improve my speed to produce codes faster and get things done more accurately. Mehul Sir and Monish Sir was so helpful that they always kept my motivation and confidence high. They gave me projects that are more skilful and as per industry standards which directly helps me to get my first job journey. I highly recommend Codify classes to everyone who wanted to upscale their knowledge and career in Web Development field.</p>
    
                                    </div>
                                </div>
                                <div className="sep1"></div>
                                <div className="desi">
                                    <div className="image">
                                        <img src={profile} alt="" />
                                    </div>
                                    <div className="footer-heading">
                                        <p className='heading-review'>
                                            Raza Masani</p>
                                        <p>Digital Marketing Expert
    
                                        </p>
                                    </div>
                                </div>
    
                            </div>
                        </div>
    
    
                    </div>
    
                </div>
                <Footer />
            </section>)}
        </>
    )
}
export default CourseData
