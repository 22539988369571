import './App.css';
import Courses from './Pages/Courses';
import Home from './Pages/Home';
import CourseDetails from './components/CourseDetails';
import { Routes, Route } from "react-router-dom";
import NewAdmin from './Pages/newAdmin'
import EditCorseData from './Pages/EditCorseData';
import EditSubheading from './Pages/EditSubheading';
import CourseData from "./components/CourseData"
import Placement from './components/Placement';
import Contact from './components/Contact';
import AdminLogin from './components/Admin/AdminLogin';
import EnqData from './components/Admin/EnqData';

function App() {
  return (
    <>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/Courses" element={<Courses />} />
        <Route path="/CourseDetails" element={<CourseDetails />} />
        <Route path='/newadmin' element={<NewAdmin />} />
        <Route path='/editcourse' element={<EditCorseData />} />
        <Route path='/editsubheading' element={<EditSubheading />} />
        <Route path='/:name/coursedata' element={<CourseData />} />
        <Route path="/placement" element={<Placement />} />
        <Route path="/contactus" element={<Contact/>} />
        <Route path="/adminlogin" element={<AdminLogin />} />
        <Route path="/enqdata" element={<EnqData />} />
        <Route path="*" element={<h1>404 Not Found</h1>} />
      </Routes>
    </>
  );
}

export default App;
