import React, { useEffect } from 'react'
import Navbar from '../components/Navbar'
import CourseCard from '../components/CourseCard'
import Footer from '../components/Footer'
const Courses = () => {
    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, [])
    return (
        <div>
            <Navbar />
            <CourseCard />
            <Footer />
        </div>
    )
}

export default Courses
