import React, { useState, useEffect } from 'react'
import { useNavigate } from "react-router-dom";


const CourseButtons = () => {

    const [courses, setCourses] = useState([]);
    const [showOption, setShowOption] = useState(true);

    const navigate = useNavigate()

    const gotToNewPage = (data) => {
        console.log(data)
        navigate("/editcourse", {state:{data}});
    }

    const getData = async () => {
        const res = await fetch("https://codify-backend-qgvr.onrender.com/get-course", {
            method: "GET",
            headers: {
                "Content-Type": "application/json"
            }
        });

        const data = await res.json();
        console.log(data[0].CourseName);

        if (res.status === 422 || !data) {
            console.log("error");

        } else {
            setCourses(data)
            console.log("get data");
        }
    }

    useEffect(() => {
        getData();
    }, [])

    return (
        <>
            <h2>Courses Button</h2>
            <div>
                
                {courses.map((element, id) => {
                    return (
                        <>
                            <button onClick={() => gotToNewPage(element.CourseName)}>{element.CourseName}</button>
                        </>
                    )
                })}
            </div>
        </>
    )
}

export default CourseButtons