import React, { useState, useEffect } from "react";
import logo from "./../assests/Navlogo.png";
import "./Navbar.css";
import { Link } from "react-router-dom";
import { FaBars, FaTimes } from "react-icons/fa";
import { IoClose } from "react-icons/io5";

const Navbar = () => {
  const [click, setClick] = useState(false);
  const [showPopup, setShowPopup] = useState(false);

  const handleClick = () => setClick(!click);

  const [getCategory, setGetCategory] = useState([]);
  const [courses, setCourses] = useState([]);

  const getData = async () => {
    const res = await fetch(
      "https://codify-backend-qgvr.onrender.com/get-category",
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    const data = await res.json();
    // console.log(data[0].category);

    if (res.status === 422 || !data) {
      console.log("error ");
    } else {
      setGetCategory(data);
    }
  };

  const getCourse = async () => {
    const res = await fetch(
      "https://codify-backend-qgvr.onrender.com/get-course",
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    const data = await res.json();

    if (res.status === 422 || !data) {
      console.log("error");
    } else {
      setCourses(data);
    }
  };

  useEffect(() => {
    getData();
    getCourse();
  }, []);

  const [inputvalue, setInputvalue] = useState({
    Name: "",
    MobileNo: "",
    Email: "",
    CourseName: "",
  });

  const setVal = (e) => {
    const { name, value } = e.target;

    setInputvalue(() => {
      return {
        ...inputvalue,
        [name]: value,
      };
    });
  };

  const onButtonClick = () => {
    setShowPopup(!showPopup);
  };

  const addEnquirydata = async (e) => {
    e.preventDefault();
    const { Name, MobileNo, Email, CourseName } = inputvalue;
    if (Name === "") {
      alert("Please enter your Name");
    } else if (MobileNo === "") {
      alert("Please enter your Mobile No.");
    } else if (Email === "") {
      alert("Please enter your Email");
    } else if (CourseName === "") {
      alert("Please enter your Course Name");
    } else {
      try {
        const response = await fetch(
          "https://codify-backend-qgvr.onrender.com/enquiry",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ Name, MobileNo, Email, CourseName }),
          }
        );

        const responseData = await response.json();

        if (responseData.status === 201) {
          alert("Query Added Successfully");
          setInputvalue({
            ...inputvalue,
            Name: "",
            MobileNo: "",
            Email: "",
            CourseName: "",
          });
        }
      } catch (error) {
        console.error(error);
      }
    }
  };

  return (
    <>
      <nav className="navbar">
        <div className="logo">
          <Link to="/">
            <img src={logo} alt="" />
          </Link>
        </div>
        <ul className={click ? "nav-menu active" : "nav-menu"}>
          <li className="nav-item">
            <Link to="/Courses">Courses</Link>
            <ul className="submenu">
              <div className="sub-menus">
                {getCategory.map((element, id) => (
                  <li className="parent-nav-heading">
                    <p>{element.category}</p>
                    <ul className="child-nav-subheading">
                      {courses.map((Celement, Cid) => {
                        if (element.category === Celement.Category) {
                          return (
                            <>
                              <li>
                                <Link
                                  to={`/${Celement.CourseName}/coursedata/`}
                                  style={{
                                    color: "black",
                                    fontFamily: '"Figtree"',
                                    // Adjust as desired
                                  }}
                                  state={{ data: Celement.CourseName }}
                                >
                                  {Celement.NavName}
                                </Link>
                              </li>
                            </>
                          );
                        }
                      })}
                    </ul>
                  </li>
                ))}
              </div>
            </ul>
          </li>
          <li className="nav-item">
            <Link to="/placement">Placements</Link>
          </li>
          <li className="nav-item">
            <Link to="/contactus">Contact Us</Link>
          </li>
          <li className="nav-item">
            <button className="signin-button" onClick={onButtonClick}>
              Enquire Now
            </button>
          </li>
        </ul>
        <div className="hamburger" onClick={handleClick}>
          {click ? (
            <FaTimes size={20} style={{ color: "black" }} />
          ) : (
            <FaBars size={20} style={{ color: "black" }} />
          )}
        </div>
      </nav>
      {showPopup ? (
        <>
          <div className="popup-form">
            <div className="enquiryForm-container">
              <div className="form-content">
                <div className="close-button">
                  <button onClick={onButtonClick}>
                    <IoClose />
                  </button>
                </div>

                <div className="Enquiry-form">
                  <h2>Enquire Form</h2>
                  <form action="">
                    <div className="input">
                      <input
                        type="text"
                        name="Name"
                        id="Name"
                        value={inputvalue.Name}
                        onChange={setVal}
                        placeholder="Name"
                      />
                    </div>
                    <div className="input">
                      <input
                        type="text"
                        name="MobileNo"
                        id="MobileNo"
                        value={inputvalue.MobileNo}
                        onChange={setVal}
                        placeholder="Mobile No."
                      />
                    </div>
                    <div className="input">
                      <input
                        type="email"
                        name="Email"
                        id="Email"
                        value={inputvalue.Email}
                        onChange={setVal}
                        placeholder="Email ID"
                      />
                    </div>
                    <div className="input">
                      <input
                        type="text"
                        name="CourseName"
                        id="CourseName"
                        value={inputvalue.CourseName}
                        onChange={setVal}
                        placeholder="Course Name"
                      />
                    </div>
                    <div className="submit">
                      <button type="submit" onClick={addEnquirydata}>
                        Get A Call Back
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}
    </>
  );
};
export default Navbar;
