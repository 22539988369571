import React, { useState, useEffect } from 'react'
import { useNavigate } from "react-router-dom";
import angleUp from './../assests/211690_up_arrow_icon.png'
import angleDown from './../assests/211687_down_arrow_icon.png'

import "./DropDown.css"

const DropDown = () => {
    const navigate = useNavigate()
    const gotToCourseDataPage = (data) => {
        navigate(`/${data}/coursedata`, { state: { data } })
    }
    const [getCategory, setGetCategory] = useState([]);
    const [cardData, setCardData] = useState([]);
    const [activeIndex, setActiveIndex] = useState(null);
    const [isCollapsed, setIsCollapsed] = useState(
        Array(getCategory.length).fill(false)
    );

    const toggleFAQ = (index) => {
        const updatedCollapsedState = [...isCollapsed];
        updatedCollapsedState[index] = !updatedCollapsedState[index];
        setActiveIndex(activeIndex === index ? null : index);
        setIsCollapsed(updatedCollapsedState);
    };

    const getData = async () => {
        const res = await fetch("https://codify-backend-qgvr.onrender.com/get-category", {
            method: "GET",
            headers: {
                "Content-Type": "application/json"
            }
        });

        const data = await res.json();

        if (res.status === 422 || !data) {
            console.log("error ");

        } else {
            setGetCategory(data)
        }
    }

    const getCoursesData = async () => {
        const res = await fetch(`https://codify-backend-qgvr.onrender.com/get-course`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json"
            }
        });

        const headingData = await res.json();
        // console.log(data[0].category);

        if (res.status === 422 || !headingData) {
            console.log("error ");

        } else {
            setCardData(headingData)
        }
    }

    useEffect(() => {
        getData();
        getCoursesData();
    }, [])

    return (
        <>
            <div className="faqqq" id="faqs">
                <div className="faq-container">
                    {getCategory.map((item, index) => (
                        <div
                            className={`faq ${activeIndex === index ? "active" : ""
                                }`}
                            key={index}
                        >
                            <div className="question-format">
                                <div className="drop-head faq-toggle"
                                        onClick={() => toggleFAQ(index)}>

                                    
                                        <h2 className="faq-title">{item.category}<div className='arrow down' ></div></h2>
                                
                                </div>
                                {/* <p className="faq-text">{item.answer}</p> */}
                                <div className="faq-text">
                                    <div className="mobile-course">
                                        {cardData.map((element, id) => {
                                            if (element.Category == item.category) {

                                                return (
                                                    <>
                                                        <div div className="Mobile-Card">
                                                            <h2>{element.CourseName}</h2>
                                                            <p>{element.Description}</p>
                                                            <p className="Mobile-Card-Button" onClick={() => gotToCourseDataPage(element.CourseName)}>Get Started</p>
                                                        </div>
                                                    </>
                                                )

                                            }
                                        })}
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </>
    );
};


export default DropDown