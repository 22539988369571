import React from "react";
import "./Why.css";
import chooselogo from './../assests/laptop.png';
import mobile from './../assests/mobile.png'
const Why = () => {
  return (
    <div className="why_choose_logo">
      <div className="why-main">
        <h2 className="underline">Why Choose Us</h2>
      </div>
      <div className="image-laptop">
        <img src={chooselogo} alt="Logo" />
      </div>
      <div className="image-mobile">
        <img src={mobile} alt="Mobileimg" />
      </div>
    </div>

  );
};

export default Why;
