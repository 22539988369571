// import React from 'react'
import React, { useState } from 'react';
import './CourseDetails.css'
import { TiTick } from "react-icons/ti";
import { IoArrowForward } from "react-icons/io5";
import learn from './../assests/learn.jpg'
import practical from './../assests/practical.png'
import assignment from './../assests/assignment.png'
import project from './../assests/projects.png'
import resume from './../assests/resume.png'
import interview from './../assests/interview.png'
import suitcase from './../assests/suitcase.png'
import highlights from './../assests/highlights.png'
import clock from './../assests/clock-white-1.png'
import trained from './../assests/student-with-graduation-cap-white-1.png'
import companies from './../assests/building-white-1.png'
import money from './../assests/money-white-1.png'
import classroom from './../assests/Classroom.png'
import profile from './../assests/profile.jpg'
import angleUp from './../assests/upward.svg'
import angleDown from './../assests/downward.svg'
import Navbar from './Navbar'
import Footer from './Footer'

const CourseDetails = () => {
    const [activeIndex, setActiveIndex] = useState(null);
    const [isCollapsed, setIsCollapsed] = useState(
        Array(FAQData.length).fill(false)
    );

    const toggleFAQ = (index) => {
        const updatedCollapsedState = [...isCollapsed];
        updatedCollapsedState[index] = !updatedCollapsedState[index];
        setActiveIndex(activeIndex === index ? null : index);
        setIsCollapsed(updatedCollapsedState);
    };
    return (
        <section>
            <Navbar/>
            <div className="coursedetails-main">
                <div className="coursedetails-header">
                    <h2>Java Full Stack Training Course</h2>
                    <p>Learn how to build Full Stack web applications & websites with React and Java Spring Boot with this project-focused course.</p>
                    <button className='enroll'>Enroll Now</button>
                </div>
                <div className="coursedetails-learn">
                    <div className="learn-head">
                        <h2>What you'll learn</h2>
                        <img src={learn} alt="" />
                    </div>
                    <div className="tick-learn">
                        <div className="left-learn">
                            <ul>
                                <li><TiTick />Develop a Full Stack web application with React front-end and Spring Boot back-end</li>
                                <li><TiTick />Learn how to build React front-end applications …. all from the beginning.</li>
                                <li><TiTick />Develop your code using modern development tools such as IntelliJ, VS Code, Maven and npm</li>
                            </ul>
                        </div>
                        <div className="left-learn">
                            <ul>
                                <li><TiTick />Learn the magic of Spring Boot – Auto Configuration, Spring Initializr and Starter Projects.</li>
                                <li><TiTick />Develop a Spring Boot back-end using REST APIs and MySQL database</li>
                                <li><TiTick />Make realtime projects on React, Java, Spring Boot, API, Microservices, Javascript, etc.</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="coursedetails-process">
                    <div className="learn-process">
                        <h2>Our Training Process</h2>
                    </div>
                    <div className="process-content">
                        <div className="practical">
                            {/* <img src={practical} alt="" /> */}
                            <IoArrowForward size={55} />
                            <p>Practical Session</p>
                        </div>
                        <div className="assignment">
                            <img src={assignment} alt="" />
                            <IoArrowForward size={55} />
                            <p>Assignment</p>
                        </div>
                        <div className="project">
                            <img src={project} alt="" />
                            <IoArrowForward size={55} />
                            <p>Projects</p>
                        </div>

                        <div className="resume">
                            <img src={resume} alt="" />
                            <IoArrowForward size={55} />
                            <p>Resume Building</p>
                        </div>
                        <div className="interview">
                            <img src={interview} alt="" />
                            <IoArrowForward size={55} />
                            <p>Interview Preparation</p>
                        </div>
                        <div className="suitcase">
                            <img src={suitcase} alt="" />
                            <p>Be Job Ready</p>
                        </div>
                    </div>
                </div>
                <div className="coursedetails-highlights">
                    <div className="highlight-bg">
                        <div className="highlights-heading">
                            <h2>Key Highlights</h2>
                            <img src={highlights} alt="" />
                        </div>
                        <div className="highlights-content">
                            <div className="highlights-left-content">
                                <ul>
                                    <li>Personalized Career Coach</li>
                                    <li>Study Material</li>
                                    <li>90% Practical Training</li>
                                    <li>Instant Doubt Solving</li>
                                    <li>Certification</li>
                                    <li>Mock Interviews</li>
                                    <li>100% Job Assurance</li>
                                    <li>Live Projects</li>
                                </ul>
                            </div>
                            <div className="highlights-right-content">
                                <div className="duration">
                                    <img src={clock} alt="" />
                                    <h5>240 Hrs</h5>
                                    <p>Training Duration</p>
                                </div>
                                <div className="duration">
                                    <img src={trained} alt="" />
                                    <h5>25000+</h5>
                                    <p>Students Trained</p>
                                </div>
                                <div className="duration">
                                    <img src={companies} alt="" />
                                    <h5>1000+</h5>
                                    <p>Hiring Companies</p>
                                </div>
                                <div className="duration">
                                    <img src={money} alt="" />
                                    <h5>20 LPA</h5>
                                    <p>Highest Fresher Salary</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="coursedetails-course-content">
                    <div className='coursedetails-content-header'>
                        <h2>Course Content</h2>
                        <img src={classroom} alt="" />
                    </div>
                    <div className="course-content">
                        <div className="course-container">
                            {/* <div className="line_one sshow-me-ccircle"></div> */}
                            <div className="course-css">
                                <h4>HTML & CSS</h4>
                                <ul>
                                    <li>Introduction to HTML</li>
                                    <li>HTML Elements</li>
                                    <li>HTML Table</li>
                                    <li>HTML Forms</li>
                                    <li>HTML5 Features</li>
                                    <li>Introduction to CSS</li>
                                    <li>Sizing Properties</li>
                                    <li>CSS Box Model</li>
                                    <li>Border property</li>
                                    <li>Background property</li>
                                    <li>Float</li>
                                    <li>Overflow property</li>
                                    <li>Visibility property</li>
                                    <li>Display property</li>
                                    <li>Flex property</li>
                                    <li>Gradients & Multiple Backgrounds</li>
                                    <li>Position property</li>
                                    <li>Transformation, Transitions and Animations</li>
                                    <li>Grid</li>
                                    <li>Media Queries</li>
                                    <li>Professional Proejcts</li>
                                </ul>
                            </div>
                        </div>
                        <div className="course-container">
                            {/* <div className="line_two sshow-me-ccircle"></div> */}
                            <div className="course-css">
                                <h4>BOOTSTRAP</h4>
                                <ul>
                                    <li>Basics Of Bootstrap</li>
                                    <li>Bootstrap Grid System</li>
                                    <li>Bootstrap Layout</li>
                                    <li>Bootstrap Content</li>
                                    <li>Working with Forms</li>
                                    <li>Operators</li>
                                    <li>Bootstrap Components</li>
                                    <li>Accordion</li>
                                    <li>Alerts</li>
                                    <li>Carousel</li>
                                    <li>Drop-down</li>
                                    <li>Modal</li>
                                    <li>Nav and Tabs</li>
                                    <li>Modal</li>
                                    <li>Navbar</li>
                                    <li>Offcanvas</li>
                                    <li>Popovers</li>
                                    <li>Toasts, tooltips, scrollspy, Spinners</li>
                                    <li>Helpers</li>
                                    <li>Utilities For functionality</li>
                                    <li>For managing content</li>
                                </ul>
                            </div>
                        </div>
                        <div className="course-container">
                            {/* <div className="line_one sshow-me-ccircle"></div> */}
                            <div className="course-css">
                                <h4>TAILWIND CSS</h4>
                                <ul>
                                    <li>Basics Of Tailwind CSS</li>
                                    <li>Installation of Tailwind CSS</li>
                                    <li>Layout</li>
                                    <li>Spacing</li>
                                    <li>Sizing</li>
                                    <li>Flexbox and Grid</li>
                                    <li>Typography</li>
                                    <li>Backgrounds</li>
                                    <li>Borders</li>
                                    <li>Effects</li>
                                    <li>Filters</li>
                                    <li>Tables</li>
                                    <li>Transitions and Animations</li>
                                    <li>Transform</li>
                                    <li>Interactivity</li>
                                    <li>Tailwind Content & styles</li>
                                    <li>Navigation</li>
                                    <li>Component</li>
                                    <li>Forms</li>
                                    <li>Data</li>
                                    <li>Helpers</li>
                                    <li>Design Blocks For functionality</li>
                                </ul>
                            </div>
                        </div>
                        <div className="course-container">
                            {/* <div className="line_one sshow-me-ccircle"></div> */}
                            <div className="course-css">
                                <h4>JAVASCRIPT</h4>
                                <ul>
                                    <li>Introduction to Javascript</li>
                                    <li>Varibales</li>
                                    <li>Datatypes</li>
                                    <li>Popup Boxes</li>
                                    <li>Built-in-functions</li>
                                    <li>Functions</li>
                                    <li>DOM</li>
                                    <li>Operators</li>
                                    <li>Math Methods</li>
                                    <li>String methods</li>
                                    <li>Conditional Statements</li>
                                    <li>Function with parameter</li>
                                    <li>Arrays and Objects</li>
                                    <li>Nested Array and Objects</li>
                                    <li>Array methods</li>
                                    <li>Loops</li>
                                    <li>Nested Loops</li>
                                    <li>Break and Continue</li>
                                    <li>Events</li>
                                    <li>Components</li>
                                    <li>Timing Functions</li>
                                    <li>DOM Elements</li>
                                    <li>Built-in-objects</li>
                                    <li>JavaScript Form Validation</li>
                                    <li>Projects & Assignments</li>
                                </ul>
                            </div>
                        </div>
                        <div className="course-container">
                            {/* <div className="line_one sshow-me-ccircle"></div> */}
                            <div className="course-css">
                                <h4>ADVANCED JAVASCRIPT</h4>
                                <ul>
                                    <li>The let keyword</li>
                                    <li>The const keyword</li>
                                    <li>Arrow Functions</li>
                                    <li>Spread and Rest Operator</li>
                                    <li>String and Object Literals</li>
                                    <li>Optional Chaining</li>
                                    <li>Default Parameter</li>
                                    <li>Array and Object Destructuring</li>
                                    <li>Array Methods</li>
                                    <li>array.filer()</li>
                                    <li>array.find()</li>
                                    <li>array.map()</li>
                                    <li>array.findIndex()</li>
                                    <li>array.reduce</li>
                                    <li>array.foreach()</li>
                                    <li>JavaScript Classes and Objects</li>
                                    <li>Callback Function</li>
                                    <li>JavaScript Hoisting</li>
                                    <li>Closure</li>
                                    <li>Session storage and Local Storage</li>
                                    <li>AJAX</li>
                                    <li>API Fetching</li>
                                    <li>Promises</li>
                                    <li>Promise methods</li>
                                    <li>ASYNC / Await</li>
                                    <li>Fetch method</li>
                                    <li>Projects & Assignments</li>
                                </ul>
                            </div>
                        </div>
                        <div className="course-container">
                            {/* <div className="line_one sshow-me-ccircle"></div> */}
                            <div className="course-css">
                                <h4>REACT JS</h4>
                                <ul>
                                    <li>Getting started with React</li>
                                    <li>Components</li>
                                    <li>React hooks</li>
                                    <li>Props in react</li>
                                    <li>Routing</li>
                                    <li>Frontend designing with react</li>
                                    <li>Forms</li>
                                    <li>State management</li>
                                    <li>Keys in react</li>
                                    <li>Advanced Topics</li>
                                    <li>Projects & Assignments</li>
                                </ul>
                            </div>
                        </div>
                        <div className="course-container">
                            {/* <div className="line_one sshow-me-ccircle"></div> */}
                            <div className="course-css">
                                <h4>CORE JAVA</h4>
                                <ul>
                                    <li>Introduction To Java</li>
                                    <li>Java Fundamentals</li>
                                    <li>Essentials of Object-Oriented Programming</li>
                                    <li>Writing Java Classes</li>
                                    <li>Packages</li>
                                    <li>Exception Handling</li>
                                    <li>I/O Operations in Java</li>
                                    <li>Multithreaded Programming</li>
                                    <li>Java Util Package / Collections Framework</li>
                                    <li>Generics</li>
                                    <li>Inner Classes</li>
                                    <li>Abstract Window Toolkit</li>
                                    <li>Collection of Framework</li>
                                    <li>Projects & Assignments</li>
                                </ul>

                            </div>
                        </div>
                        <div className="course-container">
                            {/* <div className="line_one sshow-me-ccircle"></div> */}
                            <div className="course-css">
                                <h4>SPRINGBOOT & SPRINGBOOT JPA</h4>
                                <ul>
                                    <li>Spring 1st Project</li>
                                    <li>Injections</li>
                                    <li>Creating Interface</li>
                                    <li>Java Based Config</li>
                                    <li>Bean Name</li>
                                    <li>Scope Annotation</li>
                                    <li>Autowire</li>
                                    <li>Spring To Spring Boot</li>
                                    <li>Using Annotations In Spring Boot</li>
                                    <li>Different Layers</li>
                                    <li>Service Class</li>
                                    <li>Repository Layer</li>
                                </ul>
                            </div>
                        </div>
                        <div className="course-container">
                            {/* <div className="line_one sshow-me-ccircle"></div> */}
                            <div className="course-css">
                                <h4>MICROSERVICES</h4>
                                <ul>
                                    <li>Microservice Components – Standardizing Ports and URL</li>
                                    <li>Setting up Limits Microservice</li>
                                    <li>Setting up Spring Cloud Config Server</li>
                                    <li>Projects</li>
                                </ul>
                            </div>
                        </div>
                        <div className="course-container">
                            {/* <div className="line_one sshow-me-ccircle"></div> */}
                            <div className="course-css">
                                <h4>JUNIT TESTING</h4>
                                <ul>
                                    <li>Types of unit testing</li>
                                    <li>Annotations for Junit testing</li>
                                    <li>Assert class</li>
                                    <li>JUnit examples</li>
                                    <li>Projects & Assignments</li>
                                </ul>
                            </div>
                        </div>
                        <div className="course-container">
                            {/* <div className="line_one sshow-me-ccircle"></div> */}
                            <div className="course-css">
                                <h4>MYSQL</h4>
                                <ul>
                                    <li>Introduction To MySQL</li>
                                    <li>Inserting Data</li>
                                    <li>CRUD commandsg</li>
                                    <li>String Functions</li>
                                    <li>Basic Database terminology</li>
                                    <li>MySQL constraints</li>
                                    <li>Aggregate Functions</li>
                                    <li>MySQL Stored Procedure</li>
                                    <li>Projects & Assignments</li>
                                </ul>
                            </div>
                        </div>
                        <div className="course-container">
                            {/* <div className="line_one sshow-me-ccircle"></div> */}
                            <div className="course-css">
                                <h4>PROFESSIONAL PROJECTS</h4>
                                <ul>
                                    <li>Build social network</li>
                                    <li>E-commerce website</li>
                                    <li>Blog Application</li>
                                    <li>Canva website clone</li>
                                    <li>Bookmyshow Clone</li>
                                    <li>Olx Clone</li>
                                    <li>AirBnB Clone</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="course-skills">
                    <div className="skills-heading">
                        <h2>Key Highlights</h2>
                        <img src={highlights} alt="" />
                    </div>
                    <div className="coursedetails-button">
                        <button>Java Stack</button>
                        <button>Core Java</button>
                        <button>Spring Boot</button>
                        <button> JUnit </button>
                        <button>SQL</button>
                        <button>React JS</button>
                        <button>Javascript</button>
                        <button> Advanced Javascript</button>
                        <button>Microservices</button>
                        <button>Tailwind CSS</button>
                        <button>Material UI</button>
                        <button>Bootstrap</button>
                        <button>HTML</button>
                        <button>Full Stack Web Development</button>
                    </div>
                </div>
                <div className="coursedeatils-certificate">
                    <div className="certificate-heading">
                        <h2>Course Certification</h2>
                        {/* <img src={highlights} alt="" /> */}
                    </div>
                    <div className="certificate-content">
                        <div className="certificate-left">
                            <p>Become a Certified Java Full Stack Web Developer with Codify Classes and enhance your career prospects to the next level.</p>
                            <p>This certificate serves as an official badge of your successful Java Full Stack training course completion, highlighting your expertise.</p>
                        </div>
                        <div className="certificate-right">
                            <img src={learn} alt="" />
                        </div>
                    </div>
                </div>
                <div className="coursedetails-review">
                    <h2>Genuine Reviews For Our Java Full Stack Training Course</h2>
                    <div className="student-review">
                        <div className="content">
                            <div className="review-content">
                                <p className='quote'> ❝</p>
                                <div className="review">
                                    <p>I have done my Digital Marketing Course From Codify Institute And the course was worth it and have worked on practical live projects. Also got a job from Codify Institute. Specially Thanks to Mohnish and Mehul Sir and to the faculty for helping to provide right guidance</p>

                                </div>
                            </div>
                            <div className="sep1"></div>
                            <div className="desi">
                                <div className="image">
                                    <img src={profile} alt="" />
                                </div>
                                <div className="footer-heading">
                                    <p className='heading-review'>Zagesh Rathod</p>
                                    <p>Full Stack Developer</p>
                                </div>
                            </div>

                        </div>
                        <div className="content">
                            <div className="review-content">
                                <p className='quote'> ❝</p>
                                <div className="review">
                                    <p>I joined Codify Institute for Android Development. They provide the best training for Android Development as well as placement opportunities. Everyone was humble and always ready to help. It was a life changing experience and helped me to get a job as an Android Developer fresher role. I would definitely recommend this institute for Android training</p>

                                </div>
                            </div>
                            <div className="sep1"></div>
                            <div className="desi">
                                <div className="image">
                                    <img src={profile} alt="" />
                                </div>
                                <div className="footer-heading">
                                    <p className='heading-review'>Pranali Shirke</p>
                                    <p>Android Developer</p>
                                </div>
                            </div>

                        </div>
                        <div className="content">
                            <div className="review-content">
                                <p className='quote'> ❝</p>
                                <div className="review">
                                    <p>I come from commerce background but Codify took the responsibility on themselves and made sure that I understand every concept. There are some amazing mentors in Codify. I'm glad I learnt my first programming language from her. Mehul sir is always supportive and helped me through my journey and sir arranged multiple interviews for me. I'm learning every day with the guidance of Mehul sir and Mohnish Sir, even after I completed the course they are still in touch with me and help me in any doubt that I ask. I highly recommend if you want to start your career in IT or if you want to learn new skills join this class.</p>

                                </div>
                            </div>
                            <div className="sep1"></div>
                            <div className="desi">
                                <div className="image">
                                    <img src={profile} alt="" />
                                </div>
                                <div className="footer-heading">
                                    <p className='heading-review'>
                                        Raza Masani</p>
                                    <p>Digital Marketing Expert

                                    </p>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className="student-review">
                        <div className="content">
                            <div className="review-content">
                                <p className='quote'> ❝</p>
                                <div className="review">
                                    <p>I have done my Digital Marketing Course From Codify Institute And the course was worth it and have worked on practical live projects. Also got a job from Codify Institute. Specially Thanks to Mohnish and Mehul Sir and to the faculty for helping to provide right guidance</p>

                                </div>
                            </div>
                            <div className="sep1"></div>
                            <div className="desi">
                                <div className="image">
                                    <img src={profile} alt="" />
                                </div>
                                <div className="footer-heading">
                                    <p className='heading-review'>Zagesh Rathod</p>
                                    <p>Full Stack Developer</p>
                                </div>
                            </div>

                        </div>
                        <div className="content">
                            <div className="review-content">
                                <p className='quote'> ❝</p>
                                <div className="review">
                                    <p>I joined Codify Institute for Android Development. They provide the best training for Android Development as well as placement opportunities. Everyone was humble and always ready to help. It was a life changing experience and helped me to get a job as an Android Developer fresher role. I would definitely recommend this institute for Android training</p>

                                </div>
                            </div>
                            <div className="sep1"></div>
                            <div className="desi">
                                <div className="image">
                                    <img src={profile} alt="" />
                                </div>
                                <div className="footer-heading">
                                    <p className='heading-review'>Pranali Shirke</p>
                                    <p>Android Developer</p>
                                </div>
                            </div>

                        </div>
                        <div className="content">
                            <div className="review-content">
                                <p className='quote'> ❝</p>
                                <div className="review">
                                    <p>I come from commerce background but Codify took the responsibility on themselves and made sure that I understand every concept. There are some amazing mentors in Codify. I'm glad I learnt my first programming language from her. Mehul sir is always supportive and helped me through my journey and sir arranged multiple interviews for me. I'm learning every day with the guidance of Mehul sir and Mohnish Sir, even after I completed the course they are still in touch with me and help me in any doubt that I ask. I highly recommend if you want to start your career in IT or if you want to learn new skills join this class.</p>

                                </div>
                            </div>
                            <div className="sep1"></div>
                            <div className="desi">
                                <div className="image">
                                    <img src={profile} alt="" />
                                </div>
                                <div className="footer-heading">
                                    <p className='heading-review'>
                                        Raza Masani</p>
                                    <p>Digital Marketing Expert

                                    </p>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className="student-review">
                        <div className="content">
                            <div className="review-content">
                                <p className='quote'> ❝</p>
                                <div className="review">
                                    <p>I have done my Digital Marketing Course From Codify Institute And the course was worth it and have worked on practical live projects. Also got a job from Codify Institute. Specially Thanks to Mohnish and Mehul Sir and to the faculty for helping to provide right guidance</p>

                                </div>
                            </div>
                            <div className="sep1"></div>
                            <div className="desi">
                                <div className="image">
                                    <img src={profile} alt="" />
                                </div>
                                <div className="footer-heading">
                                    <p className='heading-review'>Zagesh Rathod</p>
                                    <p>Full Stack Developer</p>
                                </div>
                            </div>

                        </div>
                        <div className="content">
                            <div className="review-content">
                                <p className='quote'> ❝</p>
                                <div className="review">
                                    <p>I joined Codify Institute for Android Development. They provide the best training for Android Development as well as placement opportunities. Everyone was humble and always ready to help. It was a life changing experience and helped me to get a job as an Android Developer fresher role. I would definitely recommend this institute for Android training</p>

                                </div>
                            </div>
                            <div className="sep1"></div>
                            <div className="desi">
                                <div className="image">
                                    <img src={profile} alt="" />
                                </div>
                                <div className="footer-heading">
                                    <p className='heading-review'>Pranali Shirke</p>
                                    <p>Android Developer</p>
                                </div>
                            </div>

                        </div>
                        <div className="content">
                            <div className="review-content">
                                <p className='quote'> ❝</p>
                                <div className="review">
                                    <p>I come from commerce background but Codify took the responsibility on themselves and made sure that I understand every concept. There are some amazing mentors in Codify. I'm glad I learnt my first programming language from her. Mehul sir is always supportive and helped me through my journey and sir arranged multiple interviews for me. I'm learning every day with the guidance of Mehul sir and Mohnish Sir, even after I completed the course they are still in touch with me and help me in any doubt that I ask. I highly recommend if you want to start your career in IT or if you want to learn new skills join this class.</p>

                                </div>
                            </div>
                            <div className="sep1"></div>
                            <div className="desi">
                                <div className="image">
                                    <img src={profile} alt="" />
                                </div>
                                <div className="footer-heading">
                                    <p className='heading-review'>
                                        Raza Masani</p>
                                    <p>Digital Marketing Expert

                                    </p>
                                </div>
                            </div>

                        </div>
                    </div>

                </div>
                {/* <div className="faqqq" id="faqs">
                    <h1 id='FAQs' >Frequently Asked Questions</h1>
                    <div className="faq-container">
                        {FAQData.map((item, index) => (
                            
                            <div
                                className={`faq ${activeIndex === index ? "active" : ""
                                    }`}
                                key={index}
                            >
                                <button
                                    className="faq-toggle"
                                    onClick={() => toggleFAQ(index)}
                                >
                                    <img
                                        src={isCollapsed[index] ? angleUp : angleDown}
                                        alt={isCollapsed[index] ? "Collapse" : "Expand"}
                                    />
                                </button>
                                <div className="question-format">
                                    <h2 className="faq-title">{item.question}</h2>
                                    <p className="faq-text">{item.answer}</p>
                                </div>
                                
                            </div>
                        ))}
                    </div>
                </div> */}
            </div>
            <Footer/>
        </section>
    )
}
const FAQData = [
    {
        question: "What is The Startup Fest 2024?",
        answer: "The E-Club Committee of St. John Technical and Eduaction Campus, Palghar, is organising an event called Startup Fest 2024 with the goal of uniting industry experts, investors, companies, and enthusiasts to celebrate innovation, encourage networking, and advance entrepreneurship.",
    },
    {
        question: "When and where will The Startup Fest 2024 take place?",
        answer: "It is planned to take place at the Main Auditorium of the St. John College of Engineering and Management in Palghar on March 12, 2024.",
    },
    {
        question: "Who can participate in The Startup Fest 2024?",
        answer: "Startups in all sectors and phases of development are welcome to attend; these include aspiring entrepreneurs, established businesses, and early-stage startups.",
    },
    {
        question: "What are the benefits of participating in The Startup Fest 2024?",
        answer: "Startups can exhibit their products, network with investors and industry professionals, get insightful feedback, and look into possible partnership options by taking part in The Startup Fest 2024.",
    },
    {
        question: "Is there a registration fee for participating in The Startup Fest 2024?",
        answer: "Startups are welcome to participate in the event for free. Promotional opportunities and upgraded booths are examples of extra services or amenities that could have a cost.",
    },
    {
        question: "Can I bring promotional materials or merchandise to The Startup Fest 2024?",
        answer: "Yes, in order to promote their goods and interact with guests, entrepreneurs are welcome to bring merchandise, prototypes, and promotional materials.",
    },
    {
        question: "Will there be opportunities for networking at The Startup Fest 2024?",
        answer: "Yes, a variety of panel talks, networking events, and interactive activities will be included at The Startup Fest 2024 with the aim of encouraging guests to form deep relationships and work together",
    },
    {
        question: "Are there any accommodation options available for attendees traveling from out of town?",
        answer: " The event organisers do not offer lodging, but there are a number of hotels and guesthouses close to the event site where participants can make reservations. It is advisable to make plans well in advance to guarantee availability.",
    },

];

export default CourseDetails
