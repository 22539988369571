import React, { useState } from 'react';

const AddCategory = () => {
    const [category, setCategory] = useState('');
    const [imageFile, setImageFile] = useState(null);

    const createCategory = async (e) => {
        e.preventDefault();
        try {
            const formData = new FormData();
            formData.append('category', category);
            formData.append('image', imageFile);

            console.log(formData);

            const res = await fetch("/create-category", {
                method: "POST",
                body: formData
            });

            const resData = await res.json();

            if (resData.status === 201) {
                setCategory('');
                setImageFile(null);
                alert('Category created successfully!');
            }
        } catch (error) {
            console.log('Error:', error);
        }
    };

    const handleChangeCategory = (e) => {
        setCategory(e.target.value);
    };

    const handleImageChange = (e) => {
        setImageFile(e.target.files[0]);
    };

    return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <h2>Add Category</h2>
            <form onSubmit={createCategory} enctype="multipart/form-data">
                <input type="text" value={category} onChange={handleChangeCategory} placeholder="Enter Category" />
                <input type="file" accept="image/*" onChange={handleImageChange} />
                <button type="submit">Submit</button>
            </form>
        </div>
    );
};

export default AddCategory;
