import React from 'react'
import './Offer.css'
const Offer = () => {
    return (
        <section>
            <div className="offer-container">
                <h2 className='sep offer-head'>What we offer</h2>
                <div className="offer-content">
                    <div className="offer-first">
                        <h2>100%</h2>
                        <p>Student Placement Records</p>
                    </div>
                    <div className="offer-first">
                        <h2>No.1</h2>
                        <p>Awarded Institute in Virar-Vasai</p>
                    </div>
                    <div className="offer-first">
                        <h2>250+</h2>
                        <p>Graduated Students</p>
                    </div>
                </div>
            </div>

        </section>
    )
}

export default Offer
