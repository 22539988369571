import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

import './Course.css'

const Course = () => {
    const navigate = useNavigate();


    const [getCategory, setGetCategory] = useState([]);
    const [courses, setCourses] = useState([]);


    const getData = async () => {
        const res = await fetch("https://codify-backend-qgvr.onrender.com/get-category", {
            method: "GET",
            headers: {
                "Content-Type": "application/json"
            }
        });

        const data = await res.json();

        if (res.status === 422 || !data) {
            console.log("error ");

        } else {
            setGetCategory(data);
        }
    }

    const getCourse = async () => {
        const res = await fetch("https://codify-backend-qgvr.onrender.com/get-course", {
            method: "GET",
            headers: {
                "Content-Type": "application/json"
            }
        });

        const data = await res.json();

        if (res.status === 422 || !data) {
            console.log("error");

        } else {
            setCourses(data);
        }
    }

    useEffect(() => {
        getData();
        getCourse();
    }, [])

    return (
        <section className="course-main">
            <h2 className='sep heading' >Our Courses</h2>
            <div className="course-container">
                {getCategory.map((element, id) => {
                    if (element.ImageName != "none") {
                        return (
                            <>
                                <div className="course-card">
                                    <img src={require(`../uploads/CardImage/${element.ImageName}`)} alt="" />
                                    <div className="course-content">
                                        <h2>{element.category}</h2>
                                        <p>Topics covered</p>
                                    </div>
                                    <div className="endpart">
                                        <div>
                                            {CourseButton.map((Celement, Cid) => {
                                                if (element.category === Celement.Category) {
                                                    return (
                                                        <div key={Cid} className="course-buttons"> {/* Adding a key for the React list */}
                                                            {Celement.Buttons.map((buttonName, bid) => {
                                                                return (
                                                                    <button key={bid}>{buttonName}</button>
                                                                );
                                                            })}
                                                        </div>
                                                    );
                                                }
                                            })}

                                        </div>
                                        <div className="course-read">
                                            <button onClick={() => navigate('/Courses')} className='know'>KNOW MORE</button>
                                        </div>
                                    </div>
                                </div>
                            </>
                        )
                    }

                })}
            </div>
            <button className='view-more' onClick={() => navigate('/Courses')}>View More</button>
        </section>
    )
}

const CourseButton = [
    {
        Category: "Full Stack Web Development",
        Buttons: ["MERN Stack", "Python Stack", "Java Stack", "Java", "DotNet Stack", "MEAN Stack", "PHP", "React JS", "Node JS", "Angular", "Laravel", "HTML - CSS", "Bootstrap", "Javascript", "Python", "Spring Boot"]
    },
    {
        Category: "Mobile App Development",
        Buttons: ["Android","IOS","Flutter","React Native","Augmented Reality"]
    },
    {
        Category: "Data Science",
        Buttons: ["Data Science","Python","Machine Learning","Deep Learning","NLP","R Programming","Tableau","Power BI","MySQL","Advance Excel With Macros","AWS"]
    },
    {
        Category: "Data Analytics",
        Buttons: ["Python","R Programming","Tableau","PowerBI","MySQL","Advance Excel With Macros","Alteryx","Business Analytics"]
    },
    {
        Category: "UX-UI Designing",
        Buttons: ["UX-UI Designing","User Experience","Figma","Adobe XD","Sketch","Photoshop","Material Design","Wireframes"]
    },
    {
        Category: "Digital Marketing",
        Buttons: ["SEO","SEM","SMM","Performance Marketing","Content Marketing","Email Marketing","Mobile App Marketing","Youtube Marketing0",
        "Google Ads","Facebook Ads","Instagram Ads","Funnels","Website Development","WordPress"]
        
    },
    {
        Category: "Software Testing",
        Buttons: ["Manual Testing","Selenium Automation","API Testing","Performance Testing","Appium","Mobile Testing","Java"]
    },
    {
        Category: "Graphic Designing",
        Buttons: ["Adobe Photoshop","Adobe Illustrator","Indesign","Adobe Premiere Pro","Figma","Retouching","Background Removal"]
    },
    {
        Category: "Cyber security",
        Buttons: ["Ethical Hacking","VAPT","Bug Bounty","Linux","System Hardware","Servers","Networking","CCNA","Live Cases"]
    },
    {
        Category: "DevOPS",
        Buttons: ["AWS Training","Microsoft Azure","Google Cloud (GCP)","Dockers","Kubernetes","Jenkins","Pipeline","Linux","Python"]
    },
    {
        Category: "3D Software Architect",
        Buttons: ["Revit","Sketchup","Autocad","Lumion","Photoshop","VRAY"]
    }
]

export default Course

