import React, { useEffect } from 'react'
import Offer from '../components/Offer'
import Course from '../components/Course';
import Training from '../components/Training';
import Why from '../components/Why';
import Footer from '../components/Footer';
import Navbar from '../components/Navbar';
import Student from '../components/Student';
import Topcontainer from '../components/Topcontainer';

const Home = () => {
    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, [])

    return (
        <div>
            <Navbar />
            <Topcontainer />
            <Offer />
            <Course />
            <Training />
            <Why />
            <Student />
            <Footer />
        </div>
    )
}

export default Home
