import React from 'react'
import './Training.css'
import map from './../assests/img.png'
import road from './../assests/road.png'
const Training = () => {
    return (
        <div className="roadmap">
            <h1 className='rodmap-heading'>Our Training Process</h1>
            <div className="sep2"></div>
            <div className="image-laptop">
                <img src={map} alt="Map"/>
            </div>
            <div className="image-mobile">
                <img src={road} alt="Map"/>
            </div>
        </div>
    )
}

export default Training
